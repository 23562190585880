import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  DollarCircleOutlined,
  FolderAddOutlined,
  AppstoreAddOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import Routes from "../Routes";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Logo from "../logo.jsx"

const { Header, Sider } = Layout;

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    window.matchMedia("(max-width: 800px)").matches && setCollapsed(true);
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div style={{ height: 24, margin: 16 }}>
          {!collapsed && (
           <Logo />
          )}{" "}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          onClick={(item) => {
            navigate(item.key);
          }}
          items={[
            {
              key: "/",
              icon: <UserOutlined style={{ fontSize: "1.3rem" }} />,
              label: "Users",
            },
            {
              key: "/transactions",
              icon: <DollarCircleOutlined style={{ fontSize: "1.3rem" }} />,
              label: "Transactions",
            },
            {
              key: "/new",
              icon: <FolderAddOutlined style={{ fontSize: "1.3rem" }} />,
              label: "New order",
            },
            {
              key: "/newProduct",
              icon: <AppstoreAddOutlined style={{ fontSize: "1.3rem" }} />,
              label: "New product",
            },
            {
              key: "/products",
              icon: <UnorderedListOutlined style={{ fontSize: "1.3rem" }} />,
              label: "Products",
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex align-items-center justify-content-between"
          style={{
            padding: "0px 20px",
            fontSize: "1.4rem",
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <button
            className="btn btn-danger"
            onClick={() => {
             Cookies.remove("token");
              navigate("/login");
            }}
          >
            Logout
          </button>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
