import { Typography, Table } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

const Users = () => {
  const { Title } = Typography;
  const [data, setData] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [details, setdetails] = useState({});

  const columns = [
    {
      title: "SNO.#",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const DisabledAcc = async (elem) => {
    const reason = prompt("Whats the reason for blocking?");
    if (reason) {
      await axios
        .put(
          process.env.REACT_APP_BASE_URL + `/api/auth/update/${elem._id}`,
          {
            newUser: {
              ...elem,
              verified: false,
            },
          },
          { headers: { token: Cookies.get("token") } }
        )
        .then(async () => {
          await axios.post(
            process.env.REACT_APP_BASE_URL +
              `/api/auth/mailstatus/${elem.email}`,
            {
              msg: `Hola!
        
        ${reason}
        `,
            },
            { headers: { token: Cookies.get("token") } }
          );
        });
    }
  };

  const DeleteAcc = async (elem) => {
    const confirmm = window.confirm("Are you sure?");

    if (confirmm) {
      await axios.post(
        process.env.REACT_APP_BASE_URL + `/api/auth/deleteUser`,
        {
          id: elem._id,
        },
        { headers: { token: Cookies.get("token") } }
      );
    }
  };

  const dataSource = [
    ...data.map((elem, i) => {
      return {
        key: i + 1,
        sno: elem.uid,
        name: elem.name + " " + elem.surname,
        email: elem.email,
        phone: elem.phone,
        direction: elem.direction,
        actions: (
          <>
            <button
              className="btn btn-warning px-2 py-1 me-2"
              onClick={() => DisabledAcc(elem)}
            >
              Disabled
            </button>
            <button
              className="btn btn-danger px-2 py-1"
              onClick={() => DeleteAcc(elem)}
            >
              Delete
            </button>
            <button
              className="btn btn-primary ms-2 px-2 py-1"
              onClick={() => {
                setShowInfo(true);
                setdetails(elem);
              }}
            >
              Details
            </button>
          </>
        ),
      };
    }),
  ];

  useEffect(() => {
    const Fetch = async () => {
      const { data } = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/admin/users",
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );

      setData(data.users);
    };
    Fetch();
  });

  return (
    <div>
      <Title level={window.matchMedia("(max-width: 600px)").matches ? 4 : 3}>
        All Registered Users
      </Title>
      <Table
        dataSource={dataSource}
        columns={columns}
        className="responsiveTable"
      />

      {showInfo && (
        <div
          className="modal_bg d-flex justify-content-center 
          align-items-center"
        >
          <div
            className="modal"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Detalles # {details.uid}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowInfo(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <p className="fs-5 fw-bold text-center">{details.type}</p>
                  <div className="d-flex flex-wrap align-items-center mt-2">
                    <div
                      style={{
                        width: window.matchMedia("(max-width: 750px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <p
                        className=""
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        <span className="small"> 1. </span>Orden : {details.uid}
                      </p>
                      <p>
                        <span className="small"> 2. </span>Nombre :{" "}
                        {details.name + " " + details.surname}
                      </p>
                      <p
                        className="mt-1"
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        <span className="small"> 3. </span>Email :{" "}
                        {details.email}
                      </p>

                      <p
                        className="mt-1 "
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        <span className="small"> 4. </span>Agencia de Entrega :{" "}
                        {details.agency}
                      </p>

                      <p
                        className="mt-1 "
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        <span className="small"> 5. </span>Teléfono 1 :{" "}
                        {details.phone}
                      </p>

                      <p
                        className="mt-1 "
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        <span className="small"> 6. </span>Teléfono 2 :{" "}
                        {details?.phone2}
                      </p>
                    </div>
                    <div
                      style={{
                        width: window.matchMedia("(max-width: 650px)").matches
                          ? "100%"
                          : "50%",
                      }}
                    >
                      <p>
                        <span className="small"> 7. </span>Tipo Documento:{" "}
                        {details.doctype}
                      </p>
                      <p>
                        <span className="small"> 8.</span> Numero ID :{" "}
                        {details.docId}
                      </p>
                      <p>
                        <span className="small"> 9. </span>Dirección Numero :{" "}
                        {details.homenumber}
                      </p>
                      <p>
                        <span className="small"> 10.</span> Pueblo, Ciudad,
                        Provincia: : {details.homevia}
                      </p>
                      <p>
                        <span className="small"> 11. </span>Código Postal:{" "}
                        {details.postalcode}
                      </p>
                      <p>
                        <span className="small"> 12.</span> Residencia:{" "}
                        {details.residence}
                      </p>
                      <p>
                        <span className="small"> 13.</span> Dirección :{" "}
                        {details.direction}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowInfo(false)}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
