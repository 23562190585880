import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { MdAddCircle } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";

const NewOrder = ({ advanced, calculate, home, btn }) => {
  const [userdata, setUserdata] = useState("");
  const [first, setFirst] = useState(true);
  const [insurance, setInsurance] = useState(true);
  const calc = useRef({ total: "" });
  const mode = useRef("Aéreo");
  const [inputValues, setInputValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([""]);

  function Calculate(packages) {
    if (mode.current === "Aéreo") {
      for (let i = 0; i < packages.length; i++) {
        let volume =
          (calc.current[`dimension${i + 1 + ""}`].largo *
            calc.current[`dimension${i + 1 + ""}`].ancho *
            calc.current[`dimension${i + 1 + ""}`].alto) /
          28326.84;

        let pesovolume =
          (calc.current[`dimension${i + 1 + ""}`].largo *
            calc.current[`dimension${i + 1 + ""}`].ancho *
            calc.current[`dimension${i + 1 + ""}`].alto) /
          6000;

        let totalCalc =
          pesovolume > calc.current[`dimension${i + 1 + ""}`].peso
            ? pesovolume * 14.99+3
            : calc.current[`dimension${i + 1 + ""}`].peso * 14.99+3;

        calc.current[`total${i + 1 + ""}`] = totalCalc.toFixed(2);
        calc.current[`volume${i + 1 + ""}`] = pesovolume;
      }

      let totals = packages.map((elem, i) => {
        return calc.current[`total${i + 1}`];
      });

      let volumes = packages.map((elem, i) => {
        return calc.current[`volume${i + 1}`];
      });

      let pesos = packages.map((elem, i) => {
        return calc.current[`dimension${i + 1 + ""}`].peso;
      });

      let totalCalc = totals.reduce(
        (acc, amount) => Number(acc ? acc : 0) + Number(amount ? amount : 0)
      );

      let volumeCalc = volumes.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let pesosCalc = pesos.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );

      calc.current.total = Number(totalCalc).toLocaleString("en-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",");
      calc.current.volume = volumeCalc.toFixed(2);
      calc.current.peso = pesosCalc;

      const formatedtotal = Number(totalCalc).toLocaleString("en-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",");

      document.getElementById("total").value = totalCalc ? formatedtotal : 0.0;
    } else {
      for (let i = 0; i < packages.length; i++) {
        let totalcalc =
         (((calc.current[`dimension${i + 1 + ""}`].largo *
            calc.current[`dimension${i + 1 + ""}`].ancho *
            calc.current[`dimension${i + 1 + ""}`].alto) /
            28326.84) *
          29.5) + 38;
        let volume =
          (calc.current[`dimension${i + 1 + ""}`].largo *
            calc.current[`dimension${i + 1 + ""}`].ancho *
            calc.current[`dimension${i + 1 + ""}`].alto) /
          28326.84;
        let pesoval =
          (calc.current[`dimension${i + 1 + ""}`].largo *
            calc.current[`dimension${i + 1 + ""}`].ancho *
            calc.current[`dimension${i + 1 + ""}`].alto) /
          6000;
        calc.current[`total${i + 1 + ""}`] = totalcalc.toFixed(2);
        calc.current[`volume${i + 1 + ""}`] = pesoval;

        // console.log(calc);
      }

      let totals = packages.map((elem, i) => {
        return calc.current[`total${i + 1}`];
      });

      let volumes = packages.map((elem, i) => {
        return calc.current[`volume${i + 1}`];
      });

      let pesos = packages.map((elem, i) => {
        return calc.current[`dimension${i + 1 + ""}`].peso;
      });

      let totalCalc = totals.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let volumeCalc = volumes.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let pesosCalc = pesos.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );

      calc.current.total = Number(totalCalc).toLocaleString("en-EN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",");
      calc.current.volume = volumeCalc.toFixed(2);
      calc.current.peso = pesosCalc;

      document.getElementById("total").value = totalCalc
        ? Number(totalCalc).toLocaleString("en-EN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",")
        : 0.0;
    }
  }

  const Continue = async () => {
    setLoading(true);
    if (userdata.name && userdata.email) {
      try {
        await axios.post(
          process.env.REACT_APP_BASE_URL + "/api/orders/new",
          {
            name: userdata.name,
            email: userdata.email,
            dimensions: packages.map((elem, i) => {
              return [calc.current[`dimension${i + 1 + ""}`]];
            }),
            peso: calc.current.peso,
            receiver: calc.current.receiver,
            total: calc.current.total,
            modality: mode.current,
            volume: calc.current.volume,
            Bultos: packages.length,
            status: "Pendiente",
            type: "Envío de Paquete",
            insurance: insurance,
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );
        calc.current = { total: "" };
        document.getElementById("largo").value = "";
        document.getElementById("ancho").value = "";
        document.getElementById("alto").value = "";
        document.getElementById("peso").value = "";
        if (document.getElementById("total")) {
          document.getElementById("total").value = "";
        }
        document.querySelectorAll(".form-control").forEach((elem) => {
          elem.value = "";
        });

        alert("Created");
        setPackages([0]);

        //   alert("Order created successfully!");
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Fill all information.");
    }
    setLoading(false);
  };

  const DeletePackage = (i) => {
    const pckgs = packages.filter((elem) => {
      return elem != i;
    });
    setPackages(pckgs);
    Calculate(pckgs);

    delete calc.current[`dimension${i + 1}`];
    delete calc.current[`total${i + 1}`];
    delete calc.current[`volume${i + 1}`];
  };

  return (
    <div className="rounded calculator">
      <div className="register-fields mt-3">
        <label>Name:</label>
        <div className="d-flex justify-content-between flex-wrap align-items-center mt-0">
          <input
            type={"text"}
            placeholder="Name"
            id="name"
            onChange={(e) =>
              setUserdata((prev) => ({ ...prev, name: e.target.value }))
            }
            className="form-control border rounded-0 mt-2 field mt-1"
          />
        </div>
      </div>
      <div className="register-fields mt-3">
        <label>Email:</label>
        <div className="d-flex justify-content-between flex-wrap align-items-center mt-0">
          <input
            type={"email"}
            placeholder="Email"
            id="email"
            onChange={(e) =>
              setUserdata((prev) => ({ ...prev, email: e.target.value }))
            }
            className="form-control border rounded-0 mt-2 field mt-1"
          />
        </div>
      </div>

      {first ? (
        <div className="mt-3">
          <div>
            <label className="fw-bold">Modalidad</label>
            <div className="d-flex mt-2">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="modality"
                  id="modality1"
                  style={{ border: "1.99px solid black" }}
                  onChange={() => {
                    setPackages([0]);
                    mode.current = "Aéreo";
                    calc.current = { total: "" };
                    document.getElementById("largo").value = "";
                    document.getElementById("ancho").value = "";
                    document.getElementById("alto").value = "";
                    document.getElementById("peso").value = "";
                    document.getElementById("total").value = "";
                  }}
                  checked={mode.current === "Aéreo" && true}
                />
                <label className="form-check-label" htmlFor="modality1">
                  Aéreo
                </label>
              </div>
              <div className="form-check ms-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="modality"
                  id="modality2"
                  style={{ border: "1.99px solid black" }}
                  onChange={() => {
                    mode.current = "Marítimo";
                    calc.current = { total: "" };
                    document.getElementById("largo").value = "";
                    document.getElementById("ancho").value = "";
                    document.getElementById("alto").value = "";
                    document.getElementById("peso").value = "";
                    if (document.getElementById("total")) {
                      document.getElementById("total").value = "";
                    }
                    setPackages([0]);
                  }}
                  checked={mode.current === "Marítimo" && true}
                />
                <label className="form-check-label" htmlFor="modality2">
                  Marítimo
                </label>
              </div>
            </div>
          </div>
          <div className="packages">
            {packages.map((e, i) => {
              return (
                <div key={i}>
                  <p className="mt-3 fw-bold">Paquete {i + 1}</p>
                  <div className="d-flex justify-content-between align-items-center mt-1">
                    <input
                      type="number"
                      className="form-control rounded"
                      placeholder="Largo"
                      id="largo"
                      value={calc.current[`dimension${i + 1 + ""}`]?.largo}
                      onInput={(e) => {
                        setInputValues({});
                        calc.current[`dimension${i + 1 + ""}`] = {
                          ...calc.current[`dimension${i + 1 + ""}`],
                          largo: e.target.value,
                        };
                        Calculate(packages);
                      }}
                    />
                    <input
                      className="form-control ms-3 rounded"
                      placeholder="Ancho"
                      type="number"
                      id="ancho"
                      value={calc.current[`dimension${i + 1 + ""}`]?.ancho}
                      onInput={(e) => {
                        setInputValues({});
                        calc.current[`dimension${i + 1 + ""}`] = {
                          ...calc.current[`dimension${i + 1 + ""}`],
                          ancho: e.target.value,
                        };
                        Calculate(packages);
                      }}
                    />
                    <input
                      className="form-control ms-3 me-2 rounded"
                      placeholder="Alto"
                      id="alto"
                      type="number"
                      value={calc.current[`dimension${i + 1 + ""}`]?.alto}
                      onInput={(e) => {
                        setInputValues({});
                        calc.current[`dimension${i + 1 + ""}`] = {
                          ...calc.current[`dimension${i + 1 + ""}`],
                          alto: e.target.value,
                        };
                        Calculate(packages);
                      }}
                    />
                    <span style={{ marginRight: "2px" }}>CM</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="w-50">
                      <label className="fw-bold">Peso</label>
                      <div className="d-flex align-items-center w-100">
                        <input
                          type="number"
                          className="form-control me-2 rounded"
                          id="peso"
                          value={calc.current[`dimension${i + 1 + ""}`]?.peso}
                          onInput={(e) => {
                            setInputValues({});
                            calc.current[`dimension${i + 1 + ""}`] = {
                              ...calc.current[`dimension${i + 1 + ""}`],
                              peso: e.target.value,
                            };
                            Calculate(packages);
                          }}
                        />{" "}
                        KG
                      </div>
                    </div>
                  </div>
                  {i !== 0 && (
                    <AiFillDelete
                      className="highlight mt-2"
                      size={26}
                      cursor={"pointer"}
                      onClick={() => DeletePackage(i)}
                    />
                  )}

                  {i === packages.length - 1 && (
                    <div className="d-flex align-items-center mt-2">
                      <MdAddCircle
                        className="highlight"
                        size={26}
                        cursor={"pointer"}
                        onClick={() =>
                          setPackages([
                            ...packages,
                            packages[packages.length - 1] + 1,
                          ])
                        }
                      />
                      <p className="ms-2">Añadir paquete</p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {!advanced && (
            <div className="mt-3">
              <label className="fw-bold">Total a pagar </label>
              <div
                className="d-flex mt-1"
                style={{ position: "relative", width: "100%" }}
              >
                <input
                  type="text"
                  className="form-control bg-white rounded"
                  id="total"
                  value={calc.current.total}
                  disabled={true}
                />
                <div className="curr">EUR</div>
              </div>
            </div>
          )}

          {/* {advanced && ( */}
          <div className="w-100 mt-3">
            <label className="fw-bold">Valor</label>
            <div className="d-flex align-items-center rounded">
              <input className="form-control me-2" type="text" />
              EUR
            </div>
          </div>

          <div className="mt-3">
            <label className="">
              Seguro de Contenido (3% sobre Valor del Contenido)
            </label>
            <div className="d-flex align-items-center rounded mt-1">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="insurance1"
                  style={{ border: "1.99px solid black" }}
                  checked={insurance}
                  onChange={() => setInsurance(true)}
                />
                <label className="form-check-label" htmlFor="insurance1">
                  Si
                </label>
              </div>
              <div className="form-check ms-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="insurance2"
                  style={{ border: "1.99px solid black" }}
                  checked={!insurance}
                  onChange={() => setInsurance(false)}
                />
                <label className="form-check-label" htmlFor="insurance2">
                  No
                </label>
              </div>
            </div>
          </div>

          {/* )} */}

          {advanced && (
            <>
              <label className="mt-3 fw-bold">Observaciones especiales:</label>
              <textarea className="form-control rounded" />
            </>
          )}

          {/* {/* {btn && ( */}
          <button onClick={Continue} className="btn btn-primary mt-3 w-100">
            {loading ? "Enviado" : "Continuar"}
          </button>
          {/* )} */}
        </div>
      ) : (
        <div className="mt-3">
          <label>NÚMERO DE ÓRDEN</label>
          <input className="form-control mt-2 rounded" />
          <button onClick={Continue} className="continue">
            BUSCAR
          </button>
        </div>
      )}
    </div>
  );
};

export default NewOrder;
