import { Table } from "antd";
import Title from "antd/es/skeleton/Title";
import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProductList = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const Fetch = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_BASE_URL + "/api/product/get",
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );
    setData(data.data);
  };

  useEffect(() => {
    Fetch();
  }, []);

  const columns = [
    {
      title: "SNO.#",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    // {
    //   title: "Ram",
    //   dataIndex: "ram",
    //   key: "ram",
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const DeleteProduct = async (id) => {
    const confirmm = window.confirm("Are you sure?");

    if (confirmm) {
      await axios.post(
        process.env.REACT_APP_BASE_URL + `/api/admin/deleteproduct`,
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
    }
    Fetch();
  };

  const dataSource = [
    ...data.map((elem, i) => {
      return {
        key: i + 1,
        sno: i + 1,
        name: elem.name,
        price: elem.price,
        // ram: elem.description.ram,
        actions: (
          <>
            <button
              className="btn btn-primary"
              onClick={() => navigate(`/newProduct/${elem._id}`)}
            >
              Update
            </button>
            <button
              className="btn btn-danger ms-3"
              onClick={() => DeleteProduct(elem._id)}
            >
              Delete
            </button>
          </>
        ),
      };
    }),
  ];

  return (
    <div>
      <Title level={window.matchMedia("(max-width: 600px)").matches ? 4 : 3}>
        All Products
      </Title>
      <Table
        dataSource={dataSource}
        columns={columns}
        className="responsiveTable"
      />
    </div>
  );
};

export default ProductList;
