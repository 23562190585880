import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const AddProduct = () => {
  const [loading, setLoading] = useState("");
  const [details, setDetails] = useState({});
  const [details2, setDetails2] = useState({});
  const [loading2, setLoading2] = useState(true);
  const [desc, setDesc] = useState("Normal");
  const [neww, setNew] = useState(false);
  const [file, setFile] = useState("");
  const id = useParams();

  const handleChange = (e) => {
    setDetails((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleChangeDesc = (e) => {
    setDetails2((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const HandleUpload = async (e) => {
    setLoading(true);
    const name = details.name;
    e.preventDefault();
    setNew(true)

    let formdata = new FormData();
    const filename = Date.now() + name;
    formdata.append("name", filename);
    formdata.append("file", file);

    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/upload",
        formdata
      );
    } catch (err) {}

    await axios.post(
      process.env.REACT_APP_BASE_URL + "/api/product/new",
      {
        description: details2,
        ...details,
        images: [filename],
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );
    setLoading(false);
    setDetails({price: "", name: ""})
    setDetails2({description: ""})
  };

  const HandleUpdate = async (e) => {
    e.preventDefault()
    setLoading(true);
    if(file){
    const name = file.name;

    let formdata = new FormData();
    const filename = Date.now() + name;
    formdata.append("name", filename);
    formdata.append("file", file);

    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/upload",
        formdata,
      );
    } catch (err) {}

    await axios.put(
      process.env.REACT_APP_BASE_URL + `/api/admin/updateproduct/${id.id}`,
      {
        description: details2,
        ...details,
        images: [filename],
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    )
    }else{
      await axios.put(
        process.env.REACT_APP_BASE_URL + `/api/admin/updateproduct/${id.id}`,
        {
          description: details2,
          ...details,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      )
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id.id && id.id.length > 0) {
      setLoading2(true);
      const Fetch = async () => {
        const { data } = await axios.get(
          process.env.REACT_APP_BASE_URL + `/api/product/getone?id=${id.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );
        setDetails({
          price: data.data.price,
          name: data.data.name,
          images: data.data.images,
        });
        let newdata = data.data;
        delete newdata["name"];
        delete newdata["price"];
        delete newdata["images"];
        setDetails2({ ...data.data.description });

        if (data.data.description?.ram?.length > 0) {
          setDesc("Mobile");
        } else {
          setDesc("Normal");
        }
        setLoading2(false);
      };
      Fetch();
    } else {
      setLoading2(false);
    }
  }, [id]);

  if (loading2) return "LOADING";

  return (
    <form
      onSubmit={(e) =>
        id.id && id.id.length > 0 ? HandleUpdate(e) : HandleUpload(e)
      }
    >
      <h3 className="fw-bold">
        {id.id && id.id.length > 0 ? "Update" : "Add"} Product
      </h3>

      {/* {details?.images && (
        <div>
          {neww ? "New" : "Old"} image{" "}
          <img src={process.env.REACT_APP_BASE_URL + "/images/" + details?.images[0]
           +".jpg"} height={50} />
        </div>
      )} */}
      <label className="mt-4">Image</label>
      <input
        type="file"
        className="form-control mt-1"
        onChange={(e) => {
          setFile(e.target.files[0]);
        }}
      />
      <label className="mt-2">Name</label>
      <input
        type="text"
        className="form-control mt-1"
        id="name"
        onChange={handleChange}
        value={details.name}
      />
      <div className="d-flex w-100">
        <div className="w-50">
          <label className="mt-2">Price</label>
          <input
            type="text"
            className="form-control mt-1"
            id="price"
            onChange={(e)=>{
              handleChange(e)
            }}
            value={details.price}
          />
        </div>
        <div className="w-50 ms-4">
          <label className="mt-2">Description mode</label>
          <select
            onChange={(e) => setDesc(e.target.value)}
            className="form-control"
            value={desc}
          >
            <option value="Normal">Normal</option>
            <option value="Mobile">Mobile</option>
          </select>
        </div>
      </div>

      {desc === "Mobile" ? (
        <>
          <div className="w-50">
            <label className="mt-2">Display</label>
            <input
              type="text"
              className="form-control mt-1"
              id="display"
              onChange={handleChangeDesc}
              value={details2.display}
            />
          </div>

          <div className="d-flex w-100">
            <div className="w-50">
              <label className="mt-2">Battery</label>
              <input
                type="text"
                className="form-control mt-1"
                id="battery"
                onChange={handleChangeDesc}
                value={details2.battery}
              />
            </div>
            <div className="w-50 ms-4">
              <label className="mt-2">RAM</label>
              <input
                type="text"
                className="form-control mt-1"
                id="ram"
                onChange={handleChangeDesc}
                value={details2.ram}
              />
            </div>
          </div>

          <div className="d-flex w-100">
            <div className="w-50">
              <label className="mt-2">Front Camera</label>
              <input
                type="text"
                className="form-control mt-1"
                id="front"
                onChange={handleChangeDesc}
                value={details2.front}
              />
            </div>
            <div className="w-50 ms-4">
              <label className="mt-2">Back Camera</label>
              <input
                type="text"
                className="form-control mt-1"
                id="back"
                onChange={handleChangeDesc}
                value={details2.back}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <label className="mt-2">Description: </label>
          <textarea
            name=""
            id="description"
            rows="5"
            className="form-control mt-2"
            onChange={(e) =>
              setDetails2((prev) => ({
                ...prev,
                [e.target.id]: e.target.value,
              }))
            }
            value={details2.description}
          ></textarea>
        </>
      )}

      <button className="btn btn-primary mt-3" type="submit">
        {loading ? "Loading..." : "Upload"}
      </button>
    </form>
  );
};

export default AddProduct;
