import { Routes, Route } from "react-router-dom";
import Transactions from "./Pages/Transactions";
import Users from "./Pages/Users";
import NewOrder from "./Pages/NewOrder";
import AddProduct from "./Pages/AddProduct";
import ProductList from "./Pages/ProductList";

export default function Rotes() {
  return (
    <Routes>
      <Route path="/" element={<Users />} />
      <Route path="/new" element={<NewOrder />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/newproduct" element={<AddProduct />} />
      <Route path="/newproduct/:id" element={<AddProduct />} />
      <Route path="/products" element={<ProductList />} />
    </Routes>
  );
}
