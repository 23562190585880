import { Typography, Table, Tag } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { CloseOutlined } from "@ant-design/icons";
import { useRef } from "react";

const Transactions = () => {
  const { Title } = Typography;
  const [show, setShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [id, setId] = useState("");
  const [details, setDetails] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [inputValues, setInputValues] = useState([[]]);
  const dimensions = useRef([inputValues]);
  const [total, setTotal] = useState(0);
  const mode = useRef("Aéreo");
  const status = useRef("false");
  const [loading, setLoading] = useState(false);

  const FetchOrders = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/orders/getall",
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      setOrders(data.orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchOrders();
  }, []);

  const data = [
    ...orders.map((elem, i) => {
      return {
        id: elem._id,
        userId: elem.userId,
        orderId: elem.orderId,
        name: elem.name,
        date: elem.date,
        type: elem.type,
        modality: elem.modality,
        Bultos: elem.Bultos ? elem.Bultos : 1,
        status: [elem.status],
        dimensions: elem.dimensions,
        description: elem.description,
        peso: elem.peso,
        total: elem.total,
        volume: elem.volume,
        type: elem.type,
        insurance: elem.insurance,
        home: elem.home,
        remarks: elem.remarks,
        payment: elem.payment,
        company: elem.company,
        shipmentID: elem.shipmentID,
        agency: elem.agency,
        receiver: elem.receiver,
        receiverID: elem.receiverID,
        receiverP: elem.receiverP,
        receiverP2: elem.receiverP2,
        content: elem.content,
        valor: elem.valor,
      };
    }),
  ];

  const CheckColor = (elem) => {
    let color = "";
    if (
      elem.trim().toUpperCase() === "PENDIENTE" ||
      elem.trim().toUpperCase() === "RECOGIENDO" ||
      elem.trim().toUpperCase() === "PAGO PENDIENTE"
    ) {
      color = "red";
    } else if (elem.trim().toUpperCase() === "RECIBIDO") {
      color = "yellow";
    } else if (elem.trim().toUpperCase() === "ENTREGADO") {
      color = "green";
    } else {
      color = "blue";
    }

    return color;
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "Type",
    },
    {
      title: "Modality",
      dataIndex: "modality",
      key: "modality",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          {status.map((stats) => {
            let color;
            if (
              stats.toUpperCase() === "PENDIENTE" ||
              stats.toUpperCase() === "RECOGIENDO" ||
              stats.toUpperCase() === "PAGO PENDIENTE"
            ) {
              color = "volcano";
            } else if (
              stats.toUpperCase() === "Procesando" ||
              stats.toUpperCase() === "RECIBIDO"
            ) {
              color = "blue";
            } else if (
              stats.toUpperCase() === "ENTREGADO" ||
              stats.toUpperCase() === "DISPONIBLE"
            ) {
              color = "green";
            } else {
              color = "yellow";
            }
            return (
              <Tag color={color} key={stats}>
                {stats.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const dataSource = [
    ...data.map((elem, i) => {
      return {
        orderId: "PQ" + elem.orderId,
        name: elem.name,
        type: elem.type,
        modality: elem.modality,
        status: elem.status,
        insurance: elem.insurance,
        payment: elem.payment,
        date: elem.date,
        actions: (
          <>
            <button
              className="btn btn-primary fs-6 me-3"
              onClick={() => {
                setShow(true);
                setId(elem);
              }}
            >
              Change Status
            </button>
            <button
              className="btn btn-success fs-6"
              onClick={() => {
                setDetails(true);
                setTotal(elem?.total ? elem.total : elem?.description?.price);
                status.current = elem.payment;
                if (elem.modality.length > 0) {
                  mode.current = elem.modality;
                }

                dimensions.current = elem.dimensions;

                if (
                  elem.type.toLowerCase() === "compra online" &&
                  elem.dimensions.length === 0
                ) {
                  dimensions.current = [
                    [
                      {
                        alto: "",
                        largo: "",
                        ancho: "",
                        peso: "",
                      },
                    ],
                  ];
                }
                setId(elem);
              }}
            >
              Change details
            </button>
            <button
              className="btn btn-warning fs-6 ms-3"
              onClick={async (e) => {
                e.target.innerHTML = "Loading...";
                setId(elem);
                setShowInfo(true);
                status.current = elem.payment;
                e.target.innerHTML = "Details";
              }}
            >
              Details
            </button>
          </>
        ),
      };
    }),
  ];

  const HandleStatus = async (status) => {
    const confirmm = window.confirm(
      `Are you sure you want to change status to : ${status}`
    );
    if (confirmm) {
      document.getElementById("bg_status").style.pointerEvents = "none";
      try {
        await axios.post(
          process.env.REACT_APP_BASE_URL + `/api/orders/status`,
          {
            status: status,
            id: id.id,
            orderId: id.orderId,
            user: id.userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );
        FetchOrders();
        document.getElementById("bg_status").style.pointerEvents = "all";
        setShow(false);
      } catch (err) {
        console.log(err);
        document.getElementById("bg_status").style.pointerEvents = "all";
      }
    }
  };

  function Calculate(packages) {
    if (mode.current === "Aéreo") {
      for (let i = 0; i < packages.length; i++) {
        let volume =
          (dimensions.current[i][0].largo *
            dimensions.current[i][0].ancho *
            dimensions.current[i][0].alto) /
          28326.84;
        let pesovolume =
          (dimensions.current[i][0].largo *
            dimensions.current[i][0].alto *
            dimensions.current[i][0].ancho) /
          6000;
        let totalCalc =
          pesovolume > dimensions.current[i][0].peso
            ? pesovolume * 14.99 + 3
            : dimensions.current[i][0].peso * 14.99 + 3;

        dimensions.current[i][0][`total${i + 1 + ""}`] = totalCalc.toFixed(2);
        dimensions.current[i][0][`volume${i + 1 + ""}`] = pesovolume.toFixed(2);
      }

      let totals = packages.map((elem, i) => {
        return dimensions.current[i][0][`total${i + 1}`];
      });

      let volumes = packages.map((elem, i) => {
        return dimensions.current[i][0][`volume${i + 1}`];
      });

      let pesos = packages.map((elem, i) => {
        return dimensions.current[i][0][`pesos${i + 1}`];
      });

      let totalCalc = totals.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let volumeCalc = volumes.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let pesosCalc = pesos.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );

      dimensions.current.total =
        Number(totalCalc).toLocaleString("en-EN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",") + "€";
      dimensions.current.volume = volumeCalc;
      dimensions.current.peso = pesosCalc;

      setTotal(
        totalCalc
          ? Number(totalCalc).toLocaleString("en-EN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",") + "€"
          : 0.0
      );
    } else {
      for (let i = 0; i < packages.length; i++) {
        let totalcalc =
          (((dimensions.current[i][0].largo *
            dimensions.current[i][0].ancho *
            dimensions.current[i][0].alto) /
            28326.84) *
          29.5)+38;
        let volume =
          (dimensions.current[i][0].largo *
            dimensions.current[i][0].ancho *
            dimensions.current[i][0].alto) /
          28326.84;
        let pesoval =
          (dimensions.current[i][0].largo *
            dimensions.current[i][0].ancho *
            dimensions.current[i][0].alto) /
          6000;
        dimensions.current[i][0][`total${i + 1 + ""}`] = totalcalc.toFixed(2);
        dimensions.current[i][0][`volume${i + 1 + ""}`] = pesoval;
        // console.log(calc);
      }
      let totals = packages.map((elem, i) => {
        return dimensions.current[i][0][`total${i + 1}`];
      });

      let volumes = packages.map((elem, i) => {
        return dimensions.current[i][0][`volume${i + 1}`];
      });

      let pesos = packages.map((elem, i) => {
        return dimensions.current[i][0][`pesos${i + 1}`];
      });

      let totalCalc = totals.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let volumeCalc = volumes.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );
      let pesosCalc = pesos.reduce(
        (acc, amount) => Number(acc) + Number(amount)
      );

      dimensions.current.total =
        Number(totalCalc).toLocaleString("en-EN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",") + "€";
      dimensions.current.volume = volumeCalc;
      dimensions.current.peso = pesosCalc;

      setTotal(
        totalCalc
          ? Number(totalCalc).toFixed(2).toLocaleString("en-EN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",") + "€"
          : 0.0
      );
    }
  }

  const HandleUpdate = async () => {
    try {
      if(id.type?.trim().toLowerCase() !== "tienda online"){
      await axios.put(
        process.env.REACT_APP_BASE_URL + `/api/orders/update/${id.id}`,
        {
          ...id,
          status: id.status[0],
          dimensions:
            id.dimensions.length > 0
              ? id.dimensions.map((elem, i) => {
                  return [...dimensions.current[i]];
                })
              : [""].map((elem, i) => {
                  return [...dimensions.current[i]];
                }),
          total: dimensions.current.total,
          peso: dimensions.current.peso,
          volume: dimensions.current.volume,
          payment: status.current,
          modality: mode.current,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      }else{
  await axios.put(
        process.env.REACT_APP_BASE_URL + `/api/orders/update/${id.id}`,
        {
          ...id,
          status: id.status[0],
          dimensions: [],
          total: dimensions.current.total,
          peso: dimensions.current.peso,
          volume: dimensions.current.volume,
          payment: status.current,
          modality: mode.current,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      }
      alert("Updated");
      FetchOrders();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <Title level={window.matchMedia("(max-width: 600px)").matches ? 4 : 3}>
          All Recent Transactions
        </Title>
        <Table
          dataSource={dataSource}
          columns={columns}
          className="responsiveTable transactions"
        />

        {show && (
          <div className="bg_status" id="bg_status">
            <div
              className="d-flex justify-content-center flex-column
             status_modal"
              style={{ position: "relative" }}
            >
              <p className="fs-5 fw-bold">Change Status :</p>
              <CloseOutlined
                style={{
                  fontSize: "1.3rem",
                  position: "absolute",
                  top: "15px",
                  right: "10px",
                }}
                onClick={() => setShow(false)}
              />

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div
                  className="status_box red d-flex justify-content-center 
                align-items-center text-white"
                  type="button"
                  onClick={() => {
                    setLoading(true);
                    HandleStatus("PENDIENTE");
                    setLoading(false);
                  }}
                  aria-disabled={loading}
                >
                  PENDIENTE
                </div>
                <div
                  className="status_box blue d-flex justify-content-center
                 align-items-center text-white"
                  type="button"
                  onClick={() => {
                    setLoading(true);
                    HandleStatus("RECIBIDO");
                    // moneiTokenHandler();
                    setLoading(false);
                  }}
                  aria-disabled={loading}
                >
                  RECIBIDO
                </div>
                <div
                  className="status_box green d-flex justify-content-center 
                align-items-center text-white"
                  type="button"
                  onClick={() => HandleStatus("Enviado")}
                >
                  ENVIADO
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div
                  className="status_box blue d-flex justify-content-center
                 align-items-center text-white w-25 me-2"
                  type="button"
                  onClick={() => HandleStatus("Aduanas")}
                >
                  ADUANAS
                </div>
                <div
                  className="status_box green d-flex justify-content-center
                 align-items-center text-white w-25 me-2"
                  type="button"
                  onClick={() => HandleStatus("Disponible")}
                >
                  DISPONIBLE
                </div>
                <div
                  className="status_box yellow d-flex justify-content-center
                 align-items-center text-white w-25 me-2"
                  type="button"
                  onClick={() => {
                    setLoading(true);
                    HandleStatus("Entregado");
                    setLoading(false);
                  }}
                >
                  ENTREGADO
                </div>

                <div
                  className="status_box red d-flex justify-content-center
                 align-items-center text-white w-25"
                  type="button"
                  onClick={() => {
                    setLoading(true);
                    HandleStatus("Anulada");
                    setLoading(false);
                  }}
                >
                  ANULADA
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {details && (
        <div className="bg_status">
          <div className="details" style={{ position: "relative" }}>
            <CloseOutlined
              style={{
                fontSize: "1.3rem",
                position: "absolute",
                top: "15px",
                right: "10px",
                zIndex: 200000,
              }}
              color="black"
              onClick={() => setDetails(false)}
            />

            <h1>Details</h1>
           {id.type?.trim().toLowerCase() !== "tienda online" && 
           <> 
           <div>
              <label className="fw-bold">Modalidad</label>
              <div className="d-flex mt-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    style={{ border: "1.99px solid black" }}
                    onChange={() => {
                      setInputValues([]);
                      mode.current = "Aéreo";
                      Calculate(dimensions.current);
                    }}
                    checked={mode.current === "Aéreo" && true}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Aéreo
                  </label>
                </div>
                <div className="form-check ms-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    style={{ border: "1.99px solid black" }}
                    onChange={() => {
                      setInputValues([]);
                      mode.current = "Marítimo";
                      Calculate(dimensions.current);
                    }}
                    checked={mode.current === "Marítimo" && true}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Marítimo
                  </label>
                </div>
              </div>
            </div>
            {id?.dimensions.length > 0
              ? id?.dimensions.map((elem, i) => {
                  return (
                    <div key={elem.orderId}>
                      <p className="fs-5 text-primary mt-3">Package {i + 1}</p>
                      <div className="d-flex justify-content">
                        <div className="w-50 px-2">
                          <label className="mt-2 fs-6">Largo</label>
                          <input
                            type="text"
                            placeholder="Largo"
                            className="form-control"
                            id="largo"
                            value={dimensions.current[i][0].largo}
                            onInput={(e) => {
                              setInputValues([]);
                              dimensions.current[i][0] = {
                                ...dimensions.current[i][0],
                                largo: e.target.value,
                              };
                              Calculate(dimensions.current);
                            }}
                          />
                        </div>
                        <div className="w-50 px-2">
                          <label className="mt-2 fs-6">Ancho</label>
                          <input
                            type="text"
                            placeholder="Ancho"
                            className="form-control"
                            id="ancho"
                            value={dimensions.current[i][0].ancho}
                            onChange={(e) => {
                              setInputValues([]);
                              dimensions.current[i][0].ancho = e.target.value;
                              Calculate(dimensions.current);
                            }}
                          />
                        </div>
                        <div className="w-50 px-2">
                          <label className="mt-2 fs-6">Alto</label>
                          <input
                            type="text"
                            placeholder="Alto"
                            className="form-control"
                            id="alto"
                            value={dimensions.current[i][0].alto}
                            onChange={(e) => {
                              dimensions.current[i][0].alto = e.target.value;
                              setInputValues([]);
                              Calculate(dimensions.current);
                            }}
                          />
                        </div>
                        <div className="w-50 px-2">
                          <label className="mt-2 fs-6">Peso</label>
                          <input
                            type="text"
                            placeholder="Peso"
                            className="form-control"
                            id="peso"
                            value={dimensions.current[i][0].peso}
                            onChange={(e) => {
                              setInputValues([]);
                              dimensions.current[i][0].peso = e.target.value;
                              Calculate(dimensions.current);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : [""].map((elem, i) => {
                  return (
                    <>
                      <div key={elem.orderId}>
                        <p className="fs-5 text-primary mt-3">
                          Package {i + 1}
                        </p>
                        <div className="d-flex justify-content">
                          <div className="w-50 px-2">
                            <label className="mt-2 fs-6">Largo</label>
                            <input
                              type="text"
                              placeholder="Largo"
                              className="form-control"
                              id="largo"
                              onInput={(e) => {
                                setInputValues([]);
                                dimensions.current[i][0] = {
                                  ...dimensions.current[i][0],
                                  largo: e.target.value,
                                };
                                Calculate(dimensions.current);
                              }}
                            />
                          </div>
                          <div className="w-50 px-2">
                            <label className="mt-2 fs-6">Ancho</label>
                            <input
                              type="text"
                              placeholder="Ancho"
                              className="form-control"
                              id="ancho"
                              onChange={(e) => {
                                setInputValues([]);
                                dimensions.current[i][0].ancho = e.target.value;
                                Calculate(dimensions.current);
                              }}
                            />
                          </div>
                          <div className="w-50 px-2">
                            <label className="mt-2 fs-6">Alto</label>
                            <input
                              type="text"
                              placeholder="Alto"
                              className="form-control"
                              id="alto"
                              onChange={(e) => {
                                dimensions.current[i][0].alto = e.target.value;
                                setInputValues([]);
                                Calculate(dimensions.current);
                              }}
                            />
                          </div>
                          <div className="w-50 px-2">
                            <label className="mt-2 fs-6">Peso</label>
                            <input
                              type="text"
                              placeholder="Peso"
                              className="form-control"
                              id="peso"
                              onChange={(e) => {
                                setInputValues([]);
                                dimensions.current[i][0].peso = e.target.value;
                                Calculate(dimensions.current);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                </>
}
            <input
              type="text"
              placeholder="Total"
              id="total"
              className="form-control mt-3"
              value={total}
              onInput={(e) => {
                setTotal(e.target.value.replaceAll("€", "") + "€");
                dimensions.current.total =
                  e.target.value.replaceAll("€", "") + "€";
                setInputValues({});
              }}
              // onChange={(e)=> e.target.value = e.target.value + "€"}
            />
            <div className="d-flex align-items-center mt-3">
              <button className="btn btn-primary" onClick={HandleUpdate}>
                Update
              </button>
              <div className="ms-3">
                <p>
                  Segura de Contenido:{" "}
                  <span className="fw-bold">
                    {id.insurance === "true" || id.insurance === true
                      ? "SI"
                      : "No"}
                  </span>
                </p>
                <p className="mt-2">Valor: {id.valor}</p>
              </div>
            </div>
            <p className="text-center mt-3 text-danger">
              SEGURO 3% - RECUERDE APLICAR SI ES EL CASO.
            </p>
          </div>
        </div>
      )}

      {showInfo && (
        <div
          className="modal_bg d-flex justify-content-center 
          align-items-center"
        >
          <div
            className="modal"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog w-100">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Detalles # PQ{id.orderId}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setShowInfo(false);
                      // status.current = "";
                    }}
                  ></button>
                </div>
                {id.type?.trim().toLowerCase() === "tienda online" ? (
                  <div className="modal-body">
                    <p className="fs-5 fw-bold text-center">{id.type}</p>
                    <div className="d-flex flex-wrap mt-2">
                      <div
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        <p className="">Orden : PQ{id.orderId}</p>
                        <p>Fecha : {id.date}</p>
                        <p>Estado : {id.status}</p>
                        <p>Modalidad: Aéreo</p>
                        <p>Contenido: {id.description.name}</p>
                      </div>
                      <div
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        <p>Total Bultos: {id.description.quantity}</p>
                        <p>Facture: {id.description.price}</p>
                        <p>Valor: {id.valor}</p>
                        <p>
                          Payment:{" "}
                          {id.payment === "false" ? "Not Paid" : "PAID"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex mt-2">
                      <button
                        className="btn btn-success"
                        disabled={id.payment === "true" ? true : false}
                        onClick={() => {
                          status.current = "true";
                          HandleUpdate();
                        }}
                      >
                        Confirmar Pago
                      </button>

                      <button
                        className="btn btn-danger ms-3"
                        disabled={id.payment === "true" ? false : true}
                        onClick={() => {
                          status.current = "false";
                          HandleUpdate();
                        }}
                      >
                        Pago Pendiente
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <p className="fs-5 fw-bold text-center">{id.type}</p>
                    <div className="d-flex flex-wrap mt-2">
                      <div
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        <p>Orden : PQ{id.orderId}</p>
                        <p>Fecha : {id.date}</p>
                        <p>Tíenda : {id.company}</p>
                        <p>Contenido : {id.content}</p>
                        <p>Nombre y Apellido Destinatario : {id.receiver}</p>
                        <p>Cédula Destinatario : {id.receiverID}</p>
                        <p>Teléfono Destinatario : {id.receiverP}</p>
                        <p>Teléfono adicional : {id.receiverP2}</p>
                        <p>Referencia de envio : {id.shipmentID}</p>
                        <p>Agencia : {id?.agency} </p>
                        <p>
                          Estado:{" "}
                          <span
                            className={`badge rounded-pill py-1 px-3 ms-1
                         ${CheckColor(id.status[0])}
                   ${id.status === "Completado" && "success"}`}
                          >
                            {id.status[0]}{" "}
                          </span>
                        </p>
                      </div>
                      <div
                        style={{
                          width: window.matchMedia("(max-width: 650px)").matches
                            ? "100%"
                            : "50%",
                        }}
                      >
                        <p>Modalidad: {id.modality}</p>
                        <p>Total Bultos : {id.Bultos}</p>
                        <p>Volumen : {id.volume}</p>
                        <p>Peso : {id.peso}</p>
                        <p>Facture: {id.total}</p>
                        <p>
                          Observaciones especiales: <br />
                          {id.remarks}
                        </p>

                        <p>
                          Segura de Contenido:{" "}
                          <span className="fw-bold">
                            {id.insurance === "true" || id.insurance === true
                              ? "SI"
                              : "No"}
                          </span>
                        </p>
                        <p>Valor: {id.valor}</p>
                        <p>
                          Payment:{" "}
                          <span className="fw-bold">
                            {status.current === "true" ? "PAID" : "NOT PAID"}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="d-flex mt-2">
                      <button
                        className="btn btn-success"
                        disabled={id.payment === "true" ? true : false}
                        onClick={() => {
                          status.current = "true";
                          HandleUpdate();
                        }}
                      >
                        Confirmar Pago
                      </button>

                      <button
                        className="btn btn-danger ms-3"
                        disabled={id.payment === "true" ? false : true}
                        onClick={() => {
                          status.current = "false";
                          HandleUpdate();
                        }}
                      >
                        Pago Pendiente
                      </button>
                    </div>

                    <div className="mt-2">
                      {id.dimensions.map((elem, i) => {
                        const Orderdimen = elem[0];

                        return (
                          <div>
                            <p className="mb-2 fw-bold">Paquete {i + 1}</p>

                            <div className="d-flex">
                              <p className="w-50">Largo: {Orderdimen.largo}</p>
                              <p className="w-50">Ancho: {Orderdimen.ancho}</p>
                              <p className="w-50">Alto: {Orderdimen.alto}</p>
                              <p className="w-50">Peso: {Orderdimen.peso}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {id.home && Object.keys(id.home).length > 1 && (
                      <>
                        <p className="highlight fw-bold fs-5 mt-3 ">
                          Domicilio par Recogidas:
                        </p>
                        <div className="d-flex flex-wrap align-items-center mt-1">
                          <p className="w-50">Residence: {id.home.residence}</p>
                          <p className="w-50">Vía: {id.home.homevia}</p>
                          <p className="w-50">Número: {id.home.homenumber}</p>
                          <p className="w-50">
                            Zona/Ciudad/Provincia: {id.home.province}
                          </p>
                          <p className="w-50">
                            Código Postal: {id.home.postalcode}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                )}

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setShowInfo(false);
                      // status.current = "";
                    }}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Transactions;
